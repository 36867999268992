<template>
  <div>
    <invoice-header-line
      description="Beschreibung"
      first="Anzahl"
      second="Preis CHF"
      third="Total CHF"
    />

    <div class="border-bottom mb-5" />

    <invoice-position-line
      description="Jahresabo inkl. 1 BKP"
      :single-price="formatFloat(subscriptionOrder.base_price)"
      :total-price="formatFloat(subscriptionOrder.base_price)"
    />
    <invoice-position-line
      v-if="subscriptionOrder.has_logo"
      description="Firmenlogo"
      :single-price="formatFloat(subscriptionOrder.logo_price)"
      :total-price="formatFloat(subscriptionOrder.total_logo_price)"
    />
    <invoice-position-line
      v-if="subscriptionOrder.has_branch_offices"
      description="Niederlassungen"
      :count="subscriptionOrder.nr_of_branch_offices"
      :single-price="formatFloat(subscriptionOrder.branch_office_price)"
      :total-price="formatFloat(subscriptionOrder.total_branch_offices_price)"
    />
    <invoice-position-line
      v-if="subscriptionOrder.has_additional_bkp"
      description="Zusätzliche BKP Nummer"
      :count="subscriptionOrder.nr_of_additional_bkp"
      :single-price="formatFloat(subscriptionOrder.additional_bkp_price)"
      :total-price="formatFloat(subscriptionOrder.total_additional_bkp_price)"
    />
    <invoice-position-line
      v-if="subscriptionOrder.has_portrait"
      description="Firmenportrait"
      :single-price="formatFloat(subscriptionOrder.portrait_price)"
      :total-price="formatFloat(subscriptionOrder.total_portrait_price)"
    />

    <invoice-summary-line
      description="Jahresabo mit gewählten Optionen"
      :total-price="formatFloat(subscriptionOrder.total_base_price)"
    />

    <invoice-header-line
      description="Beschreibung"
      first="Anzahl"
      second="Preis CHF"
      third="Total CHF"
    />

    <div class="border-bottom mb-5" />

    <invoice-position-line
      description="Listenpreis"
      :count="subscriptionOrder.nr_of_years + ' Jahre'"
      :single-price="formatFloat(subscriptionOrder.total_base_price)"
      :total-price="formatFloat(subscriptionOrder.total_list_price)"
    />
    <div class="border-bottom mb-5" />

    <invoice-header-line
      description="Rabatte"
      first="Rabatt"
      second="Ersparnis CHF"
      third="Total CHF"
    />

    <div class="border-bottom mb-5" />

    <invoice-position-line
      description="Rabatt"
      :count="formatFloat(subscriptionOrder.total_discount_percentage) + ' %'"
      :single-price="
        formatFloat(
          subscriptionOrder.total_list_price -
            subscriptionOrder.total_discount_price
        )
      "
      :total-price="formatFloat(subscriptionOrder.total_discount_price)"
    />
    <invoice-position-line
      v-if="subscriptionOrder.has_cash_discount"
      description="Skonto"
      :count="
        formatFloat(subscriptionOrder.total_cash_discount_percentage) + ' %'
      "
      :single-price="
        formatFloat(
          subscriptionOrder.total_discount_price -
            subscriptionOrder.total_cash_discount_price
        )
      "
      :total-price="formatFloat(subscriptionOrder.total_cash_discount_price)"
    />

    <div class="row pt-5">
      <div class="col-xxl-9">
        <h5 class="font-weight-bold text-dark">
          BETRAG GESCHULDET CHF
        </h5>
      </div>
      <div class="col-xxl-3 text-right">
        <h5 class="font-weight-bold text-dark">
          {{ formatFloat(subscriptionOrder.invoice.invoice_amount) }}
          CHF
        </h5>
      </div>
    </div>
    <div class="border-bottom mb-5" />
  </div>
</template>
<script>
import { utilMixin } from "@/mixins/utilMixin";
import InvoiceHeaderLine from "@/components/Invoice/InvoiceHeaderLine";
import InvoicePositionLine from "@/components/Invoice/InvoicePositionLine";
import InvoiceSummaryLine from "@/components/Invoice/InvoiceSummaryLine";

export default {
  name: "InvoiceSubscriptionOrderDetails",
  mixins: [utilMixin],
  components: {
    InvoiceHeaderLine,
    InvoicePositionLine,
    InvoiceSummaryLine
  },
  props: {
    subscriptionOrder: {
      required: true
    }
  }
};
</script>
