<template>
  <div v-if="isLoaded">
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
        <div class="row">
          <div class="offset-xxl-2 col-xxl-8">
            <invoice-header :invoice="subscriptionOrder.invoice" />

            <invoice-subscription-order-details
              :subscription-order="subscriptionOrder"
            />

            <invoice-vat-tax :invoice="subscriptionOrder.invoice" />

            <div class="mb-10" />
            <!--begin: Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2"></div>
              <div>
                <a
                  v-bind:href="subscriptionOrder.invoice.user_invoice_pdf"
                  class="btn btn-success font-weight-bold text-uppercase px-9 py-4 mr-4"
                  data-wizard-type="action-submit"
                  target="_blank"
                >
                  Rechnung als PDF herunterladen
                </a>
                <button
                  class="btn btn-danger font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-submit"
                  :disabled="subscriptionOrder.invoice.invoice_status == 'PAID'"
                >
                  Rechnung jetzt bezahlen
                </button>
              </div>
            </div>
            <!--end: Actions -->
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { LOAD_DASHBOARD_SUBSCRIPTION_ORDER } from "@/store/dashboard.module";
import InvoiceHeader from "@/components/Invoice/InvoiceHeader";
import InvoiceSubscriptionOrderDetails from "@/components/Invoice/InvoiceSubscriptionOrderDetails";
import InvoiceVatTax from "@/components/Invoice/InvoiceVatTax";

export default {
  name: "SubscriptionOrderInvoice",
  components: {
    InvoiceSubscriptionOrderDetails,
    InvoiceHeader,
    InvoiceVatTax
  },
  mixins: [utilMixin],
  data() {
    return {
      subscriptionOrder: {},
      subscriptionOrderId: this.$route.params.orderId,
      isLoaded: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await this.$store
        .dispatch(LOAD_DASHBOARD_SUBSCRIPTION_ORDER, {
          id: this.subscriptionOrderId
        })
        .then(() => {
          this.subscriptionOrder = this.getDashboardSubscriptionOrder;
        });
      this.isLoaded = true;
    }
  },
  computed: {
    ...mapGetters(["getDashboardSubscriptionOrder"])
  }
};
</script>
